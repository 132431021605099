@import 'libs/vars';
@import 'libs/functions';
@import 'libs/mixins';
@import 'libs/vendor';
@import 'libs/breakpoints';
@import 'libs/html-grid';
@import 'libs/fonts';


/*
	Story by HTML5 UP
	html5up.net | @ajlkn
	Free for personal and commercial use under the CCA 3.0 license (html5up.net/license)
*/

/* Banner (transitions) */

	.banner {

		// Mixin.
			@mixin transition-banner($event) {
				$x: null;
				$y: null;

				@if ($event == 'load') {
					$x: 'body.is-preload &';
					$y: _duration(on-load);
				}
				@else if ($event == 'scroll') {
					$x: '&.is-inactive';
					$y: _duration(on-scroll);
				}

				// Content.
					&.on#{$event}-content-fade-up {
						.content {
							@include vendor('transition', 'none');
						}

						#{$x} {
							.content {
								@include vendor('transform', 'none');
								opacity: 1;
							}
						}
					}

					&.on#{$event}-content-fade-down {
						.content {
							@include vendor('transition', 'none');
						}

						#{$x} {
							.content {
								@include vendor('transform', 'none');
								opacity: 1;
							}
						}
					}

					&.on#{$event}-content-fade-left {
						.content {
							@include vendor('transition', 'none');
						}

						#{$x} {
							.content {
								@include vendor('transform', 'none');
								opacity: 1;
							}
						}
					}

					&.on#{$event}-content-fade-right {
						.content {
							@include vendor('transition', 'none');
						}

						#{$x} {
							.content {
								@include vendor('transform', 'none');
								opacity: 1;
							}
						}
					}

					&.on#{$event}-content-fade-in {
						.content {
							@include vendor('transition', 'none');
						}

						#{$x} {
							.content {
								@include vendor('transform', 'none');
								opacity: 1;
							}
						}
					}

				// Image.
					&.on#{$event}-image-fade-up {
						.image {
							@include vendor('transition', 'none');

							img {
								@include vendor('transition', 'none');
							}
						}

						#{$x} {
							.image {
								@include vendor('transform', 'none');
								opacity: 1;

								img {
									opacity: 1;
								}
							}
						}
					}

					&.on#{$event}-image-fade-down {
						.image {
							@include vendor('transition', 'none');

							img {
								@include vendor('transition', 'none');
							}
						}

						#{$x} {
							.image {
								@include vendor('transform', 'none');
								opacity: 1;

								img {
									opacity: 1;
								}
							}
						}
					}

					&.on#{$event}-image-fade-left {
						.image {
							@include vendor('transition', 'none');

							img {
								@include vendor('transition', 'none');
							}
						}

						#{$x} {
							.image {
								@include vendor('transform', 'none');
								opacity: 1;

								img {
									opacity: 1;
								}
							}
						}
					}

					&.on#{$event}-image-fade-right {
						.image {
							@include vendor('transition', 'none');

							img {
								@include vendor('transition', 'none');
							}
						}

						#{$x} {
							.image {
								@include vendor('transform', 'none');
								opacity: 1;

								img {
									opacity: 1;
								}
							}
						}
					}

					&.on#{$event}-image-fade-in {
						.image {
							img {
								@include vendor('transition', 'none');
							}
						}

						#{$x} {
							.image {
								img {
									opacity: 1;
								}
							}
						}
					}

			}

		// On Load.
			@include transition-banner('load');

		// On Scroll.
			@include transition-banner('scroll');

  }
  
// Set's customizations

@import 'set';
@import 'player';

.logo {
  position: fixed;
  top: 20px;
  right: 20px;
  width: 50%;
  height: 10%;
  background-color: transparent;
  background-repeat: no-repeat;
  background-position: center center;
  background-size: 95% 95%;
  -webkit-transition: all 400ms ease-in;
  -moz-transition: all 400ms ease-in;
  transition: all 400ms ease-in;
  box-shadow: 0 0 10px 1px _palette(border-alt);
 }

 @media only screen and (min-width: 769px) {
  .logo  {
    position: fixed;
    top: 20px;
    right: 20px;
    width: 20%;
    height: 10%;
    background-color: transparent;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 95% 95%;
    -webkit-transition: all 400ms ease-in;
    -moz-transition: all 400ms ease-in;
    transition: all 400ms ease-in;
    box-shadow: 0 0 10px 1px _palette(border-alt);
   }
}

// Player link
#mainwrap {
  display: none;
}
#noJSalbum {
  display: unset;
}